import { useContext, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Banner from './Banner.js';
import Logo from './Logo.js';
import TCLogo from '../../images/TCLogo.png';
import LangContext from '../LangContext.js';
import './Navbar.sass';

function NavigationBar({ updateLang }) {
    const lang = useContext(LangContext);
    const [state, setState] = useState(null);
    const logoWidth = 328;

    useEffect(() => {
        const nav_container = document.getElementById('nav-container');
        const nav_banner = document.getElementById('nav-banner');
        const nav_logo = document.getElementById('nav-logo');
        const nav_linkbar_container = document.getElementById('nav-linkbar-container');
        const nav_linkbar = document.getElementById('nav-linkbar');
        function handleScroll() {
            const pos = Math.min(document.body.scrollTop, 110);
            nav_container.style.top = -pos;
            nav_banner.style.top = -pos;
            nav_logo.style.top = -pos + 26;
            nav_linkbar_container.style.top = -pos + 110;
            nav_linkbar.style.top = -pos + 110;
        }
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const links = lang == 'en' ? 
            [
                {link: '/', text: 'Intro'},
                {link: '/professor', text: 'Professor'},
                {link: '/honors', text: 'Honors'},
                {text: 'Research', dropdown: [
                    {link: '/research', text: 'Research Direction'},
                    {link: '/publications', text: 'Publications'}
                ]},
                {text: 'Members', dropdown: [
                    {link: '/members', text: 'Current Members'},
                    {link: '/alumni', text: 'Alumni'}
                ]},
                {link: '/news', text: 'News'}
            ] : [
                {link: '/', text: '簡介'},
                {link: '/professor', text: '指導教授'},
	        {link: '/honors', text: '榮譽事蹟'},
                {text: '研究', dropdown: [
                    {link: '/research', text: '研究方向'},
                    {link: '/publications', text: '學術發表'}
                ]},
                {text: '成員', dropdown: [
                    {link: '/members', text: '當前成員'},
                    {link: '/alumni', text: '畢業學長姐'}
                ]},
                {link: '/news', text: '公告'}
            ];
        setState(links);
    }, [lang]);

    return (
        <>
            <nav id='nav-container'>
                <div id='nav-banner'>
                    <Banner logoWidth={logoWidth} />
                </div>
                <div id='nav-logo'>
                    <Logo logoWidth={logoWidth} />
                </div>
                <div id='nav-linkbar-container'>
                    <Navbar id='nav-linkbar' expand='xl' variant='dark' fixed='top'>
                        <Navbar.Brand id='nav-brand' href='/'><img src={TCLogo} /></Navbar.Brand>
                        <Navbar.Collapse  className="justify-content-end" active={state == null ? null : ''}>
                            <Nav>
                                {state?.map((e, i) => {
                                    if (e.dropdown)
                                        return (
                                            <NavDropdown key={i} title={e.text}>
                                                {e.dropdown.map((e, i) => (
                                                    <NavDropdown.Item key={i} href={e.link}>{ e.text }</NavDropdown.Item>
                                                ))}
                                            </NavDropdown>
                                        );
                                    else
                                        return (
                                            <Nav.Link key={i} className='nav-link' href={e.link}>{e.text}</Nav.Link>
                                        );
                                })}
                                <div className='nav-lang-container'>
                                    <span active={lang=='en' ? 'true' : 'false'} onClick={() => {updateLang('en');}}>EN</span>  /  <span active={lang=='ch' ? 'true' : 'false'} onClick={() => {updateLang('ch');}}>中文</span>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                        <Navbar.Toggle className='nav-toggle' />
                    </Navbar>
                </div>
            </nav>
        </>
    );
}

export default NavigationBar;
