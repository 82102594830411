import { useContext, useEffect, useState } from "react";
import LangContext from "../LangContext";
import officeIcon from "../../images/office.svg";
import phoneIcon from "../../images/phone.svg";
import webIcon from "../../images/website.svg";
import './Footer.sass';

function Footer() {
    const lang = useContext(LangContext);
    const [state, setState] = useState(null);
    useEffect(() => {
        const info = lang == 'ch' ? {
            address: '國立臺灣大學｜電子工程學研究所\n10617 台北市大安區羅斯福路四段一號 電機二館232室',
            phone: '(02) 2365-5251 轉 232',
            website: 'https://access.ee.ntu.edu.tw'
        } : {
            address: 'National Taiwan University｜Graduate Institute of Electronics Engineering\n10617 Room 232, EE Building No.2, No.1, Sec. 4, Roosevelt Rd., Taipei City 106, Taiwan, ROC',
            phone: '+886-2-2365-5251 ext.232',
            website: 'https://access.ee.ntu.edu.tw'
        };
        setState(info);
    }, [lang]);
    return (
        state == null ? <></> :
        <footer className="footer">
            <table className="footer-address">
                <tbody>
                    <tr>
                        {/* <td><img src={officeIcon} /></td> */}
                        <td>{ state['address'] }</td>
                    </tr>
                </tbody>
            </table>
            <table className="footer-phone">
                <tbody>
                    <tr>
                        {/* <td><img src={phoneIcon} /></td> */}
                        <td>{ state['phone'] }</td>
                    </tr>
                </tbody>
            </table>
            {/* <table className="footer-website">
                <tbody>
                    <tr>
                        <td><img src={webIcon} /></td>
                        <td>
                            <a href={state['website']}>
                                { state['website'] }
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table> */}
        </footer>
    );
}

export default Footer;