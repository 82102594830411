import { useState, useEffect } from 'react';

import './Banner.sass';

function Banner(props) {
    let logoWidth = props.logoWidth;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let stripe_width = windowWidth - logoWidth - 26*2;

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, [windowWidth]);

    return (
        <>
            <svg version='1.1'
                baseProfile='full'
                width='100%' height='150px'>
                <defs>
                    <linearGradient id='bg-gradient' x1='0.25' y1='0.5' x2='1' y2='0.75'>
                        <stop offset='0%' stopColor='#9EC9EC' />
                        <stop offset='100%' stopColor='#3E6189' />
                    </linearGradient>
                </defs>
                <rect width='100%' height='150px' fill='url(#bg-gradient)' />
                <rect width={stripe_width * 1 / 3} x={windowWidth - stripe_width * 1 / 3} y='42px' height='9px' fill='#6594C2' />
                <rect width={stripe_width * 2 / 3} x={windowWidth - stripe_width * 2 / 3} y='59px' height='9px' fill='#6594C2' />
                <rect width={stripe_width * 3 / 3} x={windowWidth - stripe_width * 3 / 3} y='76px' height='9px' fill='#6594C2' />
                <rect width='100%' y='93px' height='9px' fill='#6594C2' />
                <rect width='100%' y='110px' height='40px' fill='#6594C2' />
            </svg>
        </>
    );
}

export default Banner;